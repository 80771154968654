import style from './Phone.module.scss';

// React
import { useEffect, useState } from 'react';

// Validator
import validator from 'validator'

// Phone Country
import { Icon } from '@iconify/react';
import { countries } from '../../utils/data/phone';


interface PhoneFieldProps {
    setPhone: React.Dispatch<React.SetStateAction<string>>;
    phoneValue: string;
    setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
}


const PhoneField: React.FC<PhoneFieldProps> = ({ setPhone, phoneValue, setIsValid }) => {
    const [activeCode, setActiveCode] = useState({
        code: 'ru',
        phone: 7,
    });

    // Open Search Menu
    const [isOpen, setIsOpen] = useState(false);
    // Search Country
    const [searchCountries, setSearchCounties] = useState([]);

    const changeInput = (e) => {
        const updCounties = countries.filter((el) => el.name.toLowerCase().startsWith(e.target.value.toLowerCase()));
        setSearchCounties(updCounties);
    }

    // Search Country Input
    useEffect(() => {
        setSearchCounties(countries);
    },[])

    // Open Country Menu
    useEffect(() => {
        setIsOpen(false);
    }, [activeCode])

    
    // Validate Phone Number
    useEffect(() => {
        if (phoneValue) {
            const fullPhoneNumber = `+${activeCode.phone}${phoneValue}`;
            const isValidPhone = validator.isMobilePhone(fullPhoneNumber, 'any', { strictMode: false });
            setIsValid(isValidPhone);

        }
    }, [phoneValue, setIsValid, activeCode.phone]);

    return (
        <div className={`${style.select_box} ${isOpen ? style.active : ''}`}>
            <div
                className={style.selected_option}
            >
                <div
                    onClick={() => setIsOpen((prev) => !prev)}
                    className={isOpen ? `${style.iconify_block} ${style.active}` : style.iconify_block}
                >
                    <Icon icon={`flag:${activeCode.code}-4x3`} />
                </div>
                <strong onClick={() => setIsOpen((prev) => !prev)}>+{activeCode.phone}</strong>
                <input
                    type="number"
                    name="tel"
                    onChange={(e) => setPhone(e.target.value)}
                />
            </div>
            <div className={`${style.options} ${isOpen ? style.active : ''}`}>
                <input
                    type="text"
                    className={style.search_box}
                    placeholder="Search Country Name"
                    onChange={changeInput}
                />
                <ol>
                    {searchCountries.map(country => (
                        <li
                            key={country.code}
                            className="option"
                            onClick={() => setActiveCode({phone: country.phone, code: country.code.toLowerCase()})}>
                            <div>
                                <Icon icon={`flag:${country.code.toLowerCase()}-4x3`} />
                                <span className="country-name">{country.name}</span>
                            </div>
                            <strong>{country.phone}</strong>
                        </li>
                    ))}
                </ol>
            </div>
        </div>
    )
}

export default PhoneField;