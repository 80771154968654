import { useEffect } from "react";

export function useOutsideClicked(
        ref: React.MutableRefObject<any>,
        setAction: React.Dispatch<React.SetStateAction<boolean>>
    ) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
            setAction(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, setAction]);
}