import { createAsyncThunk } from '@reduxjs/toolkit';

import ChatsService from '../../services/ChatsService';
import { CreateMessageFormData, UploadFileFormData } from './types';


export const requestGetChats = createAsyncThunk('/client/v1/user/request?limit=100&offset=0', async () => {
    const response = await ChatsService.getChats();
    return response;
})

export const requestGetMessages = createAsyncThunk('/client/v1/user/request/chatID', async (chatID: string | undefined) => {
    const response = await ChatsService.getMessages(chatID);
    return response;
})

export const requestCreateMessage = createAsyncThunk('/client/v1/message', async (formData: CreateMessageFormData) => {
    const response = await ChatsService.createMessage(formData);
    return response;
})

export const requestUploadFile = createAsyncThunk('client/v1/file', async (formData: UploadFileFormData) => {
    const response = await ChatsService.uploadFile(formData);
    return response;
})