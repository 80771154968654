import style from './Support.module.scss';

// Router
import { Link } from 'react-router-dom';

// Ant Design
import { Input, message as antdMessage } from 'antd';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { selectSupport } from '../../redux/support/selectors';
import { CreateFeedbackFormData, Status } from '../../redux/support/types';
import { requestFeedbackCreate } from '../../redux/support/asyncActions';

// Telegram
import { useWebApp, WebAppUser } from '@vkruglikov/react-telegram-web-app';

// Images
import tgIcon from '../../assets/img/support/tg.svg';
import vkIcon from '../../assets/img/support/vk.svg';
import emailIcon from '../../assets/img/support/email.svg';
import phoneIcon from '../../assets/img/support/phone.svg';
import whatsAppIcon from '../../assets/img/support/whatsapp.svg';

// Components
import { TelegramBackBtn } from '../../components/utils/buttons/Buttons';
import { useEffect, useState } from 'react';


type ContactUsType = {
    id: number;
    title: string;
    value: string;
    image: string;
}

const contactUs: ContactUsType[] = [
    {
        id: 1,
        title: 'info@adva.ru',
        value: 'info@adva.ru',
        image: emailIcon
    },
    {
        id: 2,
        title: '8 800 600 99 93',
        value: '8 800 600 99 93',
        image: phoneIcon
    },
    {
        id: 3,
        title: 'Telegram',
        value: 'https://t.me/111',
        image: tgIcon
    },
    {
        id: 4,
        title: 'VK',
        value: 'vk.com/adva.ru',
        image: vkIcon
    },
    {
        id: 5,
        title: 'WhatsApp',
        value: 'whatsapp.com/111',
        image: whatsAppIcon
    },
]


const { TextArea } = Input;


const SupportPage = () => {
    // Telegram
    const WebApp = useWebApp();

    const user: WebAppUser = WebApp.initDataUnsafe.user;

    // Redux
    const dispatch = useAppDispatch();
    const { status, message } = useSelector(selectSupport)

    // Form
    const [text, setText] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>(false);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (text.length > 0) {
            setDisabled(!disabled);

            const formData: CreateFeedbackFormData = {
                telegram_id: user.id ? `${user.id}` : null,
                username: user.username ? user.username : null,
                message: text,
            }
            dispatch(requestFeedbackCreate(formData))
        } else {
            messageApi.error('Введите описания вашей проблемы', 2);
        }
    }

    // Show Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    useEffect(() => {
        if (message.length > 0 || status !== Status.CALM) {
            if (status === Status.SUCCESS) {
                messageApi.success(message, 2);
            } else {
                messageApi.error(message, 2);
            }
            setDisabled(!disabled);
            setText('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message, messageApi])

    return (
        <div className={style.support_page}>
            {contextHolder}
            <TelegramBackBtn url='home/profile' />
            
            <div className={style.title}>
                <h1>Поддержка</h1>
            </div>
            <div className={style.description}>
                <p>Опишите Вашу проблему.</p>
            </div>
            <form onSubmit={handleSubmit}>
                <TextArea value={text} onChange={(e) => setText(e.target.value)} autoSize={false} />
                <button disabled={disabled}>Отправить</button>
            </form>

            <div className={style.contact_us}>
                <h2>Или вы можете связаться с нами любым удобным способом:</h2>
                {contactUs.map(elem => (
                    <Link to={elem.value} className={style.contact_us__contact} key={elem.id}>
                        <img src={elem.image} alt="icon" />
                        <p>{elem.title}</p>
                    </Link>
                ))}
            </div>
        </div>
    )
}

export default SupportPage;