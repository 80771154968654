import style from './Auth.module.scss'

// React
import { useState } from 'react';
import { useDebounce } from 'use-debounce';

// Ant Design
import { Checkbox, ConfigProvider } from 'antd';
import type { CheckboxProps } from 'antd';

// Telegram
import { useShowPopup } from "@vkruglikov/react-telegram-web-app";


import PhoneField from "../../components/Auth/Form/Phone";
import { AuthTitle } from "../../components/Auth/AuthTitle/AuthTitle";
import { TelegramMainBtnSubmit } from "../../components/utils/buttons/Buttons";


const AuthPage = () => {
        // Phone
        const [phone, setPhone] = useState('');
        const [phoneValue] = useDebounce(phone, 1000);
        const [isValid, setIsValid] = useState(true);
    
        // CheckBox
        const [checked, setChecked] = useState(false);
        const [disabled] = useState(false);
    
        // Telegram
        const showPopup = useShowPopup();
    
        const onChange: CheckboxProps['onChange'] = (e) => {
            setChecked(e.target.checked);
          };
        
        const sendPhone = () => {
            if (!isValid || !phoneValue) {
                showPopup({message: 'Номер телефона введен некорректно'})
            }
            if (!checked) {
                showPopup({message: 'Необходимо согласиться на обработку персональных данных'})
            }
    
            // Dispatch Redux
        }
        
    return (
        <div className={style.auth}>
            <AuthTitle title='Регистрация или вход' subtitle='Введите номер телефона, чтобы войти или зарегистрироаться' />
            <form>
                <PhoneField
                    setPhone={setPhone} 
                    phoneValue={phoneValue} 
                    setIsValid={setIsValid}
                />
                <div className={style.data_handling}>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorBgBase: 'ede9e9',
                                colorBorder: '#F24259',
                                borderRadius: 50,
                                colorPrimary: 'F24259'
                            }
                        }}
                    >
                        <Checkbox checked={checked} disabled={disabled} onChange={onChange}>
                            <p>Даю согласие на обработку персональных данных, соглашаюсь с <span className={style.important}>Политикой конфиденциальности</span> и принимаю условия <span className={style.important}>Пользовательского соглашения (Оферта)</span></p>
                        </Checkbox>
                    </ConfigProvider>
                    
                </div>
                <TelegramMainBtnSubmit text='Получить код' actionSubmit={sendPhone} />
            </form>
        </div>
    )
}

export default AuthPage;