import style from './Footer.module.scss';

// Router
import { Link, useLocation } from 'react-router-dom';

// Ant Design
import { HomeOutlined, ProductOutlined, MessageOutlined, UserOutlined } from '@ant-design/icons';


type HomePathType = {
    link: string;
    name: string;
    icon: React.ReactNode;
}

const paginateHome: HomePathType[] = [
    {
        link: '/home',
        name: 'Главная',
        icon: <HomeOutlined />
    },
    {
        link: '/home/services',
        name: 'Мои услуги',
        icon: <ProductOutlined />
    },
    {
        link: '/home/chats',
        name: 'Чаты',
        icon: <MessageOutlined />
    },
    {
        link: '/home/profile',
        name: 'Профиль',
        icon: <UserOutlined />
    }
]


const FooterHome = () => {
    const { pathname } = useLocation();

    return (
        <footer>
            <nav>
                <ul>
                    {paginateHome.map(({ link, name, icon }) => (
                        <li key={link}>
                            <Link to={link} className={pathname === link? style.active : ''}>
                                {icon}
                                {pathname === link && <p>{name}</p>}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
        </footer>
    )
}

export default FooterHome;