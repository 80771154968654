import style from './Buttons.module.scss';

// Navigate
import { useNavigate } from "react-router-dom";

// Telegram
import { MainButton, BackButton } from '@vkruglikov/react-telegram-web-app';

// Types
import { TypeBtn } from '../../../types/types';


interface MainBtnProps {
    text: string;
    type: TypeBtn;
    onClickAction?: any;
}

interface MainBtnTGProps {
    text: string;
    url: string;
}

interface MainBtnTGSubmitProps {
    text: string;
    actionSubmit?: any;
    disabled?: boolean;
}

interface BackBtnTGProps {
    url: string;
}


export const MainBtn: React.FC<MainBtnProps> = (
    {text, type = TypeBtn.Button, onClickAction = undefined}: MainBtnProps) => {
    return (
        <button
            type={type}
            onClick={onClickAction}
            className={style.main_btn}
        >
            {text}
        </button>
    )
}

export const MainBtnText: React.FC<MainBtnProps> = (
    {text, type = TypeBtn.Button, onClickAction = undefined}: MainBtnProps
) => {
    return (
        <button
            type={type}
            onClick={onClickAction}
            className={style.main_btn_text}
        >
            {text}
        </button>
    )
}


export const TelegramMainBtn: React.FC<MainBtnTGProps> = ({url, text}: MainBtnTGProps) => {
    // Navigate
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/${url}`);
    }
    return <MainButton text={text} color='#F24259' onClick={handleClick} />
}

export const TelegramMainBtnSubmit: React.FC<MainBtnTGSubmitProps> = ({text, actionSubmit, disabled = false}: MainBtnTGSubmitProps) => {
    return <MainButton disabled={disabled} text={text} color='#F24259' onClick={actionSubmit} />
}

export const TelegramBackPathBtn = () => {
    const navigate = useNavigate();

    return <BackButton onClick={() => navigate(-1)} />
}

export const TelegramBackBtn: React.FC<BackBtnTGProps> = ({url}: BackBtnTGProps) => {
    // Navigate
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(`/${url}`);
    }

    return (
        <BackButton onClick={handleClick} />
    )
}
