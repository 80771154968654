import style from './Welcome.module.scss';

// React
import { useEffect, useState } from 'react';

// Redux
import { useAppDispatch } from '../../redux/store';
import { requestAuth } from '../../redux/auth/asyncActions';

// Telegram
import { useWebApp, WebAppUser } from '@vkruglikov/react-telegram-web-app';

// Images
import iconHeader from '../../assets/img/Слой 2.svg';
import iconBackHeader from '../../assets/img/Vector.svg';
import iconCheckMark from '../../assets/img/Component 14.svg';

// Components
import { TelegramMainBtn } from '../../components/utils/buttons/Buttons';
import { Profile } from '../../redux/profile/types';


const WelcomePage = () => {
    const WebApp = useWebApp();

    const user: WebAppUser = WebApp.initDataUnsafe.user;
    const [isClearLocalStorage, setIsClearLocalStorage] = useState<boolean>(false);

    // Redux
    const dispatch = useAppDispatch();

    // Get Token
    if (!isClearLocalStorage) {
        localStorage.clear();
        setIsClearLocalStorage(true);
    }
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (!token) {
            // Request Get Token
            const formData: Profile = {
                'telegram_id': user.id ? `${user.id}` : null,
                'first_name': user.first_name ? user.first_name : null,
                'last_name': user.last_name ? user.last_name : null,
                'username': user.username ? user.username : null,
            }
            dispatch(requestAuth(formData));
        }
    }, [token, dispatch, user])

    return (
        <div className={style.welcome_info}>
            <div className={style.welcome_info__img}>
                <img src={iconBackHeader} alt="back-header" />
                <img src={iconHeader} alt="icon-lawyers" />
            </div>
            <div className={style.welcome_info__btn}>
                <TelegramMainBtn text='Продолжить' url='home' />
            </div>
            <div className={style.welcome_info__services}>
                <ul>
                    <li>
                        <img src={iconCheckMark} alt="check-mark" />
                        <p><span className={style.important}>Стоимость</span> услуги от 499 руб.</p>
                    </li>
                    <li>
                        <img src={iconCheckMark} alt="check-mark" />
                        <p>Консультируем <span className={style.important}>24/7</span> по телефону.</p>
                    </li>
                    <li>
                        <img src={iconCheckMark} alt="check-mark" />
                        <p><span className={style.important}>Подарок</span> за каждого приглашенного друга.</p>
                    </li>
                    <li>
                        <img src={iconCheckMark} alt="check-mark" />
                        <p>Быстро и <span className={style.important}>конфиденциально</span></p>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default WelcomePage;