export enum Status {
    CALM = 'calm',
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface AddNewImageToState {
    type: string;
    value: string;
    link: string | undefined;
}

export interface CreateMessageFormData {
    chat_id: number;
    content: string;
    file_ids?: any[];
}

export interface UploadFileFormData {
    file: string;
    file_name: string;
}

interface FeedBack {
    token: string | null;
    feedback_created: boolean;
}

interface Employee {
    id: number;
    full_name: string;
}

export interface Message {
    id: number;
    dt_created: string;
    author: string;
    kind: string;
    type: string;
    value: string;
    reply_to?: any;
    viewed?: boolean;
    link?: any;
    file_size?: any;
    metadata?: any;
    request_id?: number;
    package_alias?: string;
}

export interface ChatData {
    feedback: FeedBack;
    is_closed: boolean;
    employee: Employee;
    messages: Message[];
    dt_closed: string | null;
    specialist_id: number;
}

interface FeedbackChatsData {
    feedback_created: boolean;
    token: string;
}

export interface ChatsData {
    closed: boolean;
    dt_closed: any;
    viewed: boolean;
    request_id: number;
    service_name: string;
    phone: string;
    feedback: FeedbackChatsData;
    dt_created: string;
    value: string;
    assigned: boolean;
    freelancers_assigned: [];
    freelancers_in_work: [];
    last_from_partner: boolean;
    is_video_conference: boolean;
    start_time: any;
    duration: number;
    is_single_package: boolean;
    service_id: number;
    package_alias: string;
    package_name: string;
}

export interface ChatsSliceState {
    status: Status | string;
    message: string;
    next_page_exists: boolean;
    chats: ChatsData[] | [];
    closedChats: ChatsData[] | [];
    activeChat: ChatData | null;
    file_ids: any[];
}