import { Link } from 'react-router-dom';
import style from './Consultation.module.scss';

// Ant Design
import { RightCircleFilled } from '@ant-design/icons';


type Props = {
    img: string;
    text: string;
    link: string
}

export const Consultation: React.FC<Props> = ({img, text, link}) => {
    return (
        <Link to={`/home/service-info?name=${link}`} className={style.consultations__elem}>
            <img src={img} alt='icon' />
            <p>{text}</p>
        </Link>
    )
}

export const ConsultationAll = () => {
    return (
        <Link to={'areas-law'} className={style.consultations__all}>
            <p>Все</p>
            <p>области</p>
            <p>права</p>
            <RightCircleFilled />
        </Link>
    )
}