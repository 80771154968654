import axios from 'axios';

import { API_URL } from '../http';
import { Profile } from '../redux/profile/types';


export default class AuthService {
    static async auth(formData: Profile) {
        const res = await axios.post(`${API_URL}telegram-web-app/v1/auth/telegram`, formData)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        });
        return res;
    }
}
