import { createSlice } from '@reduxjs/toolkit';

import { requestFeedbackCreate } from './asyncActions';

import { SupportSliceState, Status } from './types';


const initialState: SupportSliceState = {
    status: Status.CALM as Status,
    message: '',
}

const supportSlice = createSlice({
    name: 'support',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(requestFeedbackCreate.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestFeedbackCreate.fulfilled, (state, action) => {
            if (action.payload) {
                state.status = Status.SUCCESS;
                state.message = 'Сообщение успешно отправлено';
            } else {
                state.status = Status.ERROR;
                state.message = 'Произошла ошибка при отправке запроса в поддержку';
            }
        })
        builder.addCase(requestFeedbackCreate.rejected, (state, action) => {
            state.status = Status.ERROR;
        })
    }
})

export default supportSlice.reducer;