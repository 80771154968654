import { createAsyncThunk } from '@reduxjs/toolkit';

import { CreateFeedbackFormData } from './types';

import SupportService from '../../services/SupportService';


export const requestFeedbackCreate = createAsyncThunk('/telegram-web-app/v1/feedback_telegram', async (formData: CreateFeedbackFormData) => {
    const response = await SupportService.createFeedback(formData);
    return response;
})