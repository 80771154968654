import style from './Service.module.scss';

// React
import { useEffect, useState, useRef } from 'react';

// Ant Design
import { message as antdMessage } from 'antd';

// Navigate
import { useNavigate } from "react-router-dom";

// Routes
import { useParams } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { useOutsideClicked } from '../../utils/outsideClick';
import { selectMyServices } from '../../redux/my-services/selectors';
import { requestActiveService } from '../../redux/my-services/asyncActions';
import { setMessage, setRequestId, setServiceByAlias } from '../../redux/my-services/slice';

// Components
import Spinner from '../../components/utils/spinner/Spinner';
import { TelegramBackBtn } from '../../components/utils/buttons/Buttons';


const Service = () => {
    let { serviceId } = useParams();
    const navigate = useNavigate();

    // Choose Button
    const wrapperRef = useRef(null);
    const [active, setActive] = useState<boolean>(false);
    
    useOutsideClicked(wrapperRef, setActive);

    // Current Service
    const [chooseServ, setChooseServ] = useState('Услуга не выбрана');

    function setChooseService(verbose_name: string) {
        setChooseServ(verbose_name);
        setActive(false);
    }

    // Redux
    const dispatch = useAppDispatch();
    const { activeService, message, request_id } = useSelector(selectMyServices);

    useEffect(() => {
        if (serviceId) {
            dispatch(setServiceByAlias(parseInt(serviceId)))
        }
    }, [dispatch, serviceId])

    // Show Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    // Activate Service
    const [comment, setComment] = useState('');

    const handleActivateService = (e: any) => {
        e.preventDefault();
        const chooseService = activeService?.services.filter(srv => srv.verbose_name === chooseServ);
        if (chooseService?.length === 0) {
            messageApi.error('Пожалуйста выберите услугу', 2);
            return;
        }
        if (comment.length === 0) {
            messageApi.error('Пожалуйста опишите ваш вопрос', 2);
            return;
        }
        if (chooseService) {
            const chooseServiceID = chooseService[0].id;

            dispatch(requestActiveService({
                comment: comment,
                service_id: chooseServiceID,
            }))
        }
    }

    useEffect(() => {
        if (message.length > 0) {
            if (request_id) {
                navigate(`/home/chat/${request_id}`);
            } else {
                messageApi.error(message, 2);
            }
        }

        return () => {
            dispatch(setMessage(''));
            dispatch(setRequestId(null));
        }
    }, [message, request_id, dispatch, messageApi, navigate])

    return (
        <div className={style.service}>
            {contextHolder}
            <TelegramBackBtn url='home/services' />
            {!activeService
                ? (
                    <Spinner />
                )
                : (
                    <>
                        <div className={style.service__title}>
                            <h1>Пакет {activeService.verbose_name}</h1>
                        </div>
                        <div className={style.service__choose}>
                            <p>{chooseServ}</p>
                            <button onClick={() => setActive(true)}>Сменить</button>
                        </div>
                        <div className={style.service__description}>
                            <p>Опишите Ваш вопрос</p>
                        </div>
                        <form>
                            <textarea
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                placeholder='Опишите подробно Вашу проблему...'
                            />
                            <button onClick={handleActivateService}>Далее</button>
                        </form>
                        {active && (
                            <>
                                <div className={style.service__background_dark}></div>
                                <div className={style.service__choose_service} ref={wrapperRef}>
                                    <h2>Выбрать услугу</h2>
                                    <ul>
                                        {activeService.services.map((serv, idx) => (
                                            serv.quantity !== 0 && 
                                            <li key={idx} onClick={() => setChooseService(serv.verbose_name)}>
                                                {serv.verbose_name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        )}
                    </>
                )
            }
        </div>
    )
}

export default Service;