import style from './InviteFriend.module.scss';

import { useEffect } from 'react';

// Ant Design
import { message as antdMessage } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { Status } from '../../redux/invite-code/types';

// Images
import priceInvite from '../../assets/img/invite-friend/price.svg';

// Components
import { selectInviteCode } from '../../redux/invite-code/selectors';
import { TelegramBackPathBtn } from '../../components/utils/buttons/Buttons';
import { requestGetInviteCode } from '../../redux/invite-code/asyncActions';


const InviteFriend = () => {
    // Show Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    // Redux
    const dispatch = useAppDispatch();

    const { invite_code, message, status } = useSelector(selectInviteCode);

    // Get Invite Code
    useEffect(() => {
        if (!invite_code && status === Status.CALM) {
            dispatch(requestGetInviteCode());
        }
    }, [dispatch, invite_code, status])

    useEffect(() => {
        if (status === Status.ERROR) {
            messageApi.error(message);
        }
    }, [status, message, messageApi])

    const clickBoardLink = (e: any) => {
        e.preventDefault();
        try {
            const boardLink = `https://t.me/${process.env.REACT_APP_TELEGRAM_USERNAME_BOT}?start=${invite_code}`;

            // Creating a temporary text field
            const textArea = document.createElement('textarea');
            textArea.value = boardLink;
            document.body.appendChild(textArea);

            // Selecting text in a field
            textArea.select();
            textArea.setSelectionRange(0, 99999); // Для мобильных устройств

            try {
                // Copy Text to clipboard
                const successful = document.execCommand('copy');
                const msg = successful ? 'Ссылка успешно скопирована' : 'Произошла ошибка при копировании ссылки';
                messageApi.info(msg, 2);
            } catch (err) {
                messageApi.error('Произошла ошибка при копировании ссылки', 2);
            }

            // Delete temporary text field
            document.body.removeChild(textArea);

        } catch (error) {
            messageApi.error('Произошла ошибка при копировании ссылки', 2);
        }
    }

    return (
        <main className={style.invite_friend}>
            {contextHolder}
            <TelegramBackPathBtn />
            <div className={style.title}>
                <h1>Приведи друга</h1>
            </div>
            <div className={style.sub_title}>
                <h2>Дарим подарок за каждого, кто приобретает услуга по вашему приглашению.</h2>
            </div>
            <div className={style.image}>
                <img src={priceInvite} alt="price-icon" />
            </div>
            <div className={style.info_promotion}>
                <div className={style.info_promotion__title}>
                    <h2>Как работает акция?</h2>
                </div>
                <div className={style.info_promotion__steps}>
                    <ul>
                        <li>
                            <p>1. Скопируйте ссылку</p>
                            <button onClick={clickBoardLink}>
                                <p>Ссылка</p>
                                <CopyOutlined />
                            </button>
                        </li>
                        <li>
                            <p>2. Отправьте ссылку другу</p>
                        </li>
                        <li>
                            <p>3. Ваш друг приобретает любую услугу</p>
                        </li>
                        <li>
                            <p>4. Мы дарим вам устную консультацию</p>
                        </li>
                    </ul>
                </div>
                <div className={style.info_promotion__rules}>
                    <p>Правила и условия акции</p>
                </div>
            </div>
        </main>
    )
}

export default InviteFriend;