import style from './Profile.module.scss';

// Router
import { Link } from 'react-router-dom';

// Telegram
import { useWebApp, WebAppUser } from '@vkruglikov/react-telegram-web-app';

// Ant Design
import { Avatar } from 'antd';
import { MehOutlined, NumberOutlined, RightCircleFilled, UserOutlined } from '@ant-design/icons';


type ProfileMenuType = {
    id: number;
    name: string;
    link: string;
}

const profileMenu: ProfileMenuType[] = [
    {
        id: 1,
        name: 'Мои документы',
        link: "/home/my-documents"
    },
    // {
    //     id: 2,
    //     name: 'История моих обращений',
    //     link: "/home/my-appeals"
    // },
    {
        id: 3,
        name: 'Поддержка',
        link: "/home/support"
    },
    {
        id: 4,
        name: 'Правовая информация',
        link: "/home/legal-info"
    },
    {
        id: 5,
        name: 'Приведи друга',
        link: "/home/invite-friend"
    },
]


const Profile = () => {
    // Telegram
    const WebApp = useWebApp();

    const user: WebAppUser = WebApp.initDataUnsafe.user;

    return (
        <div className={style.profile}>
            <div className={style.profile_info}>
                <Avatar
                    style={{height: '88px', width: '87px'}}
                    icon={<UserOutlined />}
                />
                <div>
                    <ul>
                        <li className={style.profile_info_user_id}>
                            <NumberOutlined />
                            <p>{user.id}</p>
                        </li>
                        <li className={style.profile_info_user}>
                            <UserOutlined />
                            <p>{user.first_name} {user.last_name}</p>
                        </li>
                        <li className={style.profile_info_username}>
                            <MehOutlined />
                            <p>{user.username}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={style.profile_menu}>
                <ul>
                    {profileMenu.map(item => (
                        <li key={item.id}>
                            <Link to={item.link}>
                                <p>{item.name}</p>
                                <RightCircleFilled />
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={style.profile_actions}>
                <div className={style.profile_actions__version}>
                    <p>Версия приложения: 1.0.0</p>
                </div>
            </div>
        </div>
    )
}

export default Profile;