import './Header.module.scss';

// Images
import companyLogo from '../../../assets/img/company_logo.jpg';
import companyName from '../../../assets/img/company_name.svg';


const HeaderHome = () => {
    return (
        <header>
            <img src={companyLogo} alt="company-logo" />
            <img src={companyName} alt="company-name" />
        </header>
    )
}

export default HeaderHome;
