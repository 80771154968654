import style from './Services.module.scss';

// React
import { useEffect } from 'react';

// Date
import Moment from 'moment';

// Ant Design
import { CheckCircleFilled, PlusSquareFilled } from '@ant-design/icons';

// Types
import { TypeBtn } from '../../types/types';
import { Status } from '../../redux/auth/types';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { selectMyServices } from '../../redux/my-services/selectors';
import { requestMyServices } from '../../redux/my-services/asyncActions';

// Router
import { Link } from 'react-router-dom';

// Images
import lawyerImg from '../../assets/img/services/lawyer.svg';
import shadowBack from '../../assets/img/shadow_background.svg';

// Components
import Spinner from '../../components/utils/spinner/Spinner';
import { MainBtn } from '../../components/utils/buttons/Buttons';


const Services = () => {
    // Redux
    const dispatch = useAppDispatch();

    const { status, my_services } = useSelector(selectMyServices);

    useEffect(() => {
        if (!my_services.length && status === Status.CALM) {
            dispatch(requestMyServices());
        }
    }, [my_services, dispatch, status])

    return (
        <main className={my_services.length > 0 ? style.relative_main : ''}>
            <img src={shadowBack} className={style.shadow_back} alt="shadow-back" />
            <div className={style.title}>
                <h1>Мои услуги</h1>
            </div>
            {!my_services.length && status === Status.LOADING
                ? (
                    <Spinner />
                )
                : (
                    my_services.length > 0
                        ? (
                            <div className={style.exist_services}>
                                {my_services.map((el, idx) => (
                                    <div className={style.service} key={`${el.alias}-${idx}`}>
                                        <p className={style.service__start_date}>{Moment(el.dt_start).format('DD.MM.YYYY')}</p>
                                        <h2>Пакет: "{el.verbose_name}"</h2>
                                        <ul>
                                            {el.services.map((service_el, idx_serv) => (
                                                <li key={idx_serv}>
                                                    <div className={style.service_info}>
                                                        {service_el.quantity === 0
                                                            ? <CheckCircleFilled />
                                                            : <div className={style.circle_null}></div>
                                                        }
                                                        <p className={service_el.quantity === 0 ? style.quantity_zero : ''}>{service_el.verbose_name}</p>
                                                    </div>
                                                    <div className={style.service__quantity}>{service_el.quantity}/{service_el.initial_quantity}</div>
                                                </li>
                                            ))}
                                        </ul>
                                        <div className={style.service__info_end}>
                                            <p>Действителен до: <span>{Moment(el.dt_end).format('DD.MM.YYYY')}</span></p>
                                        </div>
                                        <div className={style.service__start}>
                                            <Link to={`/home/service/${idx}`}>
                                                <button>Старт</button>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                                <div className={style.add_service}>
                                    <Link to={'/home/service-info'}>
                                        <PlusSquareFilled />
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className={style.not_exist_sub_title}>
                                    <p>Нет доступных услуг</p>
                                </div>
                                <div className={style.button_block}>
                                    <Link to={'/home/service-info'}>
                                        <MainBtn type={TypeBtn.Button} text='Перейти к покупке' />
                                    </Link>
                                </div>
                                <div className={style.img_block}>
                                    <img src={lawyerImg} alt="lawyer-img" />
                                </div>
                            </>
                        )
                )}
        </main>
    )
}

export default Services;