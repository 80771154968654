import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProfileSliceState, Profile } from './types';


const initialState: ProfileSliceState = {
    profile: null,
}

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfile(state, action: PayloadAction<Profile>) {
            state.profile = action.payload;
        },
    },
    extraReducers: (builder) => {}
})

export const { setProfile } = profileSlice.actions;
export default profileSlice.reducer;