export enum Status {
    CALM = 'calm',
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface Documents {
    id: number;
    dt_created: string;
    author: string;
    kind: string;
    type: string;
    value: string;
    link: string;
    file_size: string;
    metadata: any;
    request_id: number;
    package_alias: string;
}

export interface DocumentsSliceState {
    status: Status | string;
    message: string;
    documents: Documents[] | [];
}