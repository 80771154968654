import style from './LegalInfo.module.scss';

// Telegram
import { useWebApp } from '@vkruglikov/react-telegram-web-app';

// Components
import { TelegramBackBtn } from '../../components/utils/buttons/Buttons';


type LegalInfoType = {
    id: number;
    name: string;
    url: string;
}

const legalInfoType: LegalInfoType[] = [
    {
        id: 1,
        name: 'Политика конфиденциальности',
        url: 'https://admin.a2ervice.ru/media/content/company/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8_ADVA_UhQytWm_feVVJbV_uKir7CD_hX4Taox_A1nzguh_cRSyxE0.pdf'
    },
    {
        id: 2,
        name: 'Правила акции. Условия использования промокодов',
        url: 'https://adva.org.ru/docs/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0_%D0%B0%D0%BA%D1%86%D0%B8%D0%B8._%D0%A3%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F_%D0%BF%D1%80%D0%B5%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F_%D0%BF%D1%80%D0%BE%D0%BC%D0%BE%D0%BA%D0%BE%D0%B4%D0%BE%D0%B2_%D0%B0%D0%B4%D0%B2%D0%B0_1.0_FBpnxnm_QTP7Aj_N9f7a9j2.pdf'
    },
    {
        id: 3,
        name: 'Пользовательское соглашение (Оферта)',
        url: 'https://adva.org.ru/docs/%D0%A0%D1%83%D0%BA%D0%BE%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F.pdf'
    },
    {
        id: 4,
        name: 'Форма возврата',
        url: 'https://forms.yandex.ru/cloud/62d12601c96a069b09e503f4/'
    },
]


const LegalInfo = () => {
    const WebApp = useWebApp();

    const handleOpenLink = (e: any, id: number) => {
        e.preventDefault();

        const link = legalInfoType.find(item => item.id === id);
        if (link) {
            WebApp.openLink(link.url);
        }
    }

    return (
        <div className={style.legal_info}>
            <TelegramBackBtn url='home/profile' />
            <h1>Правовая информация</h1>
            <ul>
                {legalInfoType.map(item => (
                    <p
                        key={item.id}
                        onClick={(e) => handleOpenLink(e, item.id)}
                    >{item.name}</p>
                ))}
            </ul>
        </div>
    )
}

export default LegalInfo;