import { useEffect } from "react";

type Props = {
    isRunning: boolean;
    elapsedTime: number;
    recordingTime: number;
    setElapsedTime: React.Dispatch<React.SetStateAction<number>>;
}


const AudioTimer = ({ isRunning, elapsedTime, setElapsedTime, recordingTime }: Props) => {

    useEffect(() => {
        let intervalId: any;
        if (isRunning) {
            // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
            intervalId = setInterval(() => setElapsedTime(elapsedTime + 1), 10);
        }
        return () => clearInterval(intervalId);
    }, [isRunning, setElapsedTime, elapsedTime]);

    //  calculation
    const seconds = Math.floor((elapsedTime % 6000) / 100);
    const milliseconds = elapsedTime % 100;

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
            <div className="record" style={{height: '10px', width: '10px', borderRadius: '50%', background: 'red'}}></div>
            <div className="time">
                <span>{recordingTime}</span>
                {/* <span className=" w-[23px] inline-block "> {seconds.toString().padStart(2, "0")}:</span>
                <span className=" w-[23px] inline-block ml-3" >{milliseconds.toString().padStart(2, "0")}</span> */}
            </div>
        </div>
    );
};

export default AudioTimer;