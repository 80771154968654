export enum Status {
    CALM = 'calm',
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface getPackageByAliasType {
    alias: string;
    type: string;
}

export interface PackageService {
    service_id: number;
    is_video_conference: boolean;
    verbose_name: string;
    description: string;
    quantity: number;
    configs: [];
} 

export interface PeriodService {
    value: number;
    type: string;
    partner_redirect: boolean;
    partner_redirect_link: string;
    partner_redirect_description: string;
}

export interface Package {
    alias: string;
    image_thumbnail: string;
    dark_theme_image_thumbnail: any;
    verbose_name: string;
    description: string;
    detailed_description: string | null;
    mobile_description: string | null;
    price: number;
    services: PackageService[] | [];
    period: PeriodService;
    css_class: string;
    is_single_package: boolean;
    is_one_service_package: boolean;
    is_certificate: boolean;
    sub_packages: [];
    category: [];
}

export interface PackagesSliceState {
    status: Status | string;
    message: string;
    packages: Package[] | [];
    singlePackages: Package[] | [];
    activePackage: Package | null;
}