import style from './AuthTitle.module.scss';


interface AuthTitleProps {
    title: string;
    subtitle: string;
}


export const AuthTitle: React.FC<AuthTitleProps> = ({title, subtitle}: AuthTitleProps) => {
    return (
        <>
            <div className={style.title}>
                <h1>{title}</h1>
            </div>
            <div className={style.sub_title}>
                <p>{subtitle}</p>
            </div>
        </>
    )
}