import style from './areasLaw.module.scss';

// Images
import consultationIcon from '../../assets/img/consultations/1.svg';

// Components
import { serviceInfo } from '../../types/types';
import { TelegramBackBtn } from '../../components/utils/buttons/Buttons';
import { Consultation } from '../../components/Home/Consultation/Consultation';


const AreasLaw = () => {
    return (
        <main>
            <h1>Области права</h1>
            <TelegramBackBtn url='home' />
            <div className={style.areas_law}>
                {serviceInfo.map(elem => (
                    <Consultation
                        key={elem.id}
                        text={elem.name}
                        link={elem.query}
                        img={consultationIcon}
                    />
                ))}
            </div>
        </main>
    )
}

export default AreasLaw;