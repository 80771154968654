import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { requestCreateMessage, requestGetChats, requestGetMessages, requestUploadFile } from './asyncActions';

import { AddNewImageToState, ChatsData, ChatsSliceState, Message, Status } from './types';


const initialState: ChatsSliceState = {
    message: '',
    status: Status.CALM as Status,
    next_page_exists: false,
    chats: [],
    closedChats: [],
    activeChat: null,
    file_ids: [],
}

const myChatsSlice = createSlice({
    name: 'chats',
    initialState,
    reducers: {
        addNewTextMessage: (state, action: PayloadAction<string>): void => {
            if (state.activeChat && state.activeChat.messages.length > 0) {
                // Create New Message
                const newMessage: Message = {
                    id: Date.now(),
                    author: 'Dev Jedi',
                    kind: 'customer',
                    value: action.payload,
                    type: 'text',
                    reply_to: null,
                    viewed: false,
                    dt_created: new Date().toISOString(),
                };
                // Add New Message
                state.activeChat.messages.push(newMessage);
            }
        },
        addNewImageMessage: (state, action: PayloadAction<AddNewImageToState>): void => {
            if (state.activeChat && state.activeChat.messages.length > 0) {
                // Create New Message
                const newMessage: Message = {
                    id: Date.now(),
                    author: 'Dev Jedi',
                    kind: 'customer',
                    type: action.payload.type,
                    value: action.payload.value,
                    link: action.payload.link,
                    dt_created: new Date().toISOString(),
                }
                // Add New Message
                state.activeChat.messages.push(newMessage);
            }
        },
        removeFileById: (state, action: PayloadAction<number>): void => {
            state.file_ids.splice(action.payload, 1);
        },
        clearFileIds: (state): void => {
            state.file_ids = [];
        },
        clearActiveChat: (state): void => {
            state.activeChat = null;
        }
    },
    extraReducers: (builder) => {
        // Get Chats
        builder.addCase(requestGetChats.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetChats.fulfilled, (state, action) => {
            state.status = Status.SUCCESS;

            if (action.payload) {
                const chats: any[] = [];
                const closedChats: any[] = [];
                
                // Filter by Closed
                action.payload.data.forEach((chat: ChatsData) => {
                    if (chat.closed) {
                        closedChats.push(chat);
                    } else {
                        chats.push(chat);
                    }
                })

                state.chats = chats;
                state.closedChats = closedChats;
                state.next_page_exists = action.payload.next_page_exists;
            }
        })
        builder.addCase(requestGetChats.rejected, (state, action) => {
            state.status = Status.ERROR;
        })
        // Get Messages
        builder.addCase(requestGetMessages.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetMessages.fulfilled, (state, action) => {
            state.status = Status.SUCCESS;
            state.activeChat = action.payload;
        })
        builder.addCase(requestGetMessages.rejected, (state, action) => {
            state.status = Status.ERROR;
        })
        // Create Message
        builder.addCase(requestCreateMessage.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestCreateMessage.fulfilled, (state, action) => {
            state.status = Status.SUCCESS;
        })
        builder.addCase(requestCreateMessage.rejected, (state, action) => {
            state.status = Status.ERROR;
        })
        // Upload File
        builder.addCase(requestUploadFile.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestUploadFile.fulfilled, (state, action) => {
            state.status = Status.SUCCESS;
            if (action.payload) {
                state.file_ids.push(action.payload.file_id);
            }
        })
        builder.addCase(requestUploadFile.rejected, (state, action) => {
            state.status = Status.ERROR;
        })
    },
})

export const { addNewTextMessage, removeFileById, addNewImageMessage, clearFileIds, clearActiveChat } = myChatsSlice.actions;

export default myChatsSlice.reducer;