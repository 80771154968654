import style from './ServiceInfo.module.scss';

// React
import React, { useEffect, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';
import { Status } from '../../redux/auth/types';
import { useAppDispatch } from '../../redux/store';
import { selectPackages } from '../../redux/packages/selectors';
import { requestPackages } from '../../redux/packages/asyncActions';

// Route
import { Link, useLocation } from "react-router-dom";

// Ant Design
import { Modal } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

// Types
import { serviceInfo } from '../../types/types';

// Components
import Spinner from '../../components/utils/spinner/Spinner';
import { TelegramBackBtn } from '../../components/utils/buttons/Buttons';


function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ServiceInfo = () => {
    // Get Description Service Info
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [description, setDescription] = useState<string>('');
    const [title, setTitle] = useState<string>('');

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleClick = (title: string, text: string) => {
        setTitle(title);
        setDescription(text);
        setIsModalOpen(true);
    }

    // Query Params
    let query = useQuery();
    const name = query.get('name');
    const service = serviceInfo.find(elem => elem.query === name);

    // Redux
    const dispatch = useAppDispatch();
    const { status, packages, singlePackages } = useSelector(selectPackages);

    // Get Information Packages
    useEffect(() => {
        if (!packages.length && status === Status.CALM) {
            dispatch(requestPackages());
        }
    }, [packages, dispatch, status])

    return (
        <main>
            <Modal title={title} open={isModalOpen} onCancel={handleCancel} footer={null}>
                <p dangerouslySetInnerHTML={{__html: description}}></p>
            </Modal>
            <TelegramBackBtn url='home/' />
            {!packages.length ? (
                <Spinner />
            ) : (
                <div className={style.service_info}>
                    <div className={style.description}>
                        <h1>{service?.name}</h1>
                        <p>{service?.description}</p>
                    </div>
                    <div className={style.our_services_block}>
                        <h2>Наши Услуги</h2>
                        <div className={style.our_services}>
                            {singlePackages.length > 0 && singlePackages.map(pkg => (
                                <div className={style.our_services__service} key={pkg.alias}>
                                    <h3 className={style.title}>{pkg.verbose_name}</h3>
                                    <div className={style.line}></div>
                                    <p className={style.price}>{pkg.price} ₽</p>
                                    <p className={style.validity}>
                                        Срок действия услуги: <span>{pkg.period.value} дней</span>
                                    </p>
                                    <div className={style.actions}>
                                        <InfoCircleOutlined onClick={() => handleClick(pkg.verbose_name, pkg.description)} />
                                        <Link to={`/home/service-pay/${pkg.alias}/1`}>
                                            <button>Заказать</button>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={style.better_together_block}>
                        <h2>Вместе Выгоднее</h2>
                        <div className={style.better_services}>
                            {packages.map(pkg => (
                                <div className={style.better_services__service} key={pkg.alias}>
                                    <h3 className={style.title}>{pkg.verbose_name}</h3>
                                    <div className={style.line}></div>
                                    <p className={style.price}>{pkg.price} ₽</p>
                                    <p className={style.validity}>
                                        Срок действия услуги: <span>{pkg.period.value} дней</span>
                                    </p>
                                    <div className={style.actions}>
                                        <ul>
                                            {pkg.services.map((service) => (
                                                <li key={service.verbose_name}>
                                                    <InfoCircleOutlined onClick={() => handleClick(service.verbose_name, service.description)} />
                                                    <p>{service.verbose_name} - {service.quantity}</p>
                                                </li>
                                            ))}
                                        </ul>
                                        <div className={style.actions__btn}>
                                            <Link to={`/home/service-pay/${pkg.alias}/0`}>
                                                <button>Заказать</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </main>
    )
}

export default ServiceInfo;