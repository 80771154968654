export enum Status {
    CALM = 'calm',
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface AuthSliceState {
    status: Status | string;
    message: string;
}


export type FetchSignInResponse = {
    status: string;
    message: string;
}