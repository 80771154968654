import $api from '../http/index';
import { CreateFeedbackFormData } from '../redux/support/types';


export default class SupportService {
    static async createFeedback(formData: CreateFeedbackFormData) {
        const res = await $api.post('telegram-web-app/v1/feedback_telegram', formData)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
}