import $api from '../http/index';


export default class PackagesService {
    static async getPackages() {
        const res = await $api.get('client/v1/auth/packages')
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
}