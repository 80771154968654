export enum TypeBtn {
    Button = 'button',
    Submit = 'submit',
    Reset = 'reset',
}

export interface OtpValues {
    otp: Array<string> | undefined;
}

type ServiceInfoType = {
    id: number;
    name: string;
    query: string;
    description: string;
}

export const serviceInfo: ServiceInfoType[] = [
    {
        id: 1,
        name: 'Кредитное правоотношения',
        query: 'credit-right',
        description: 'Консультирование по вопросам разрешения споров с банками и коллекторскими компаниями; проверка и юридический анализ кредитных договоров и договоров ипотеки; помощь в рассрочке долга.'
    },
    {
        id: 2,
        name: 'Сделки с недвижимостью',
        query: 'real-estate',
        description: 'Консультирование и огранизация сделок покупки/продажи/аренды квартиры, дома или земельного участка; снятие арестов; перевод помещений из жилых в нежилые и наоборот.'
    },
    {
        id: 3,
        name: 'Семейное право',
        query: 'family-right',
        description: 'Помощь в составлении брачного договора, взыскании алиментов, расторжение брака, разделе общего имущества, лишении родительских прав, определении места жительства ребенка и тому подобное.'
    },
    {
        id: 4,
        name: 'Наследственное право',
        query: 'inheritance-right',
        description: 'Разъяснение положений законодательства по оформлении завещания, определение доли в наследстве, восстановление срока на принятия наследства, признании права собственности на наследственное имущество и тому подобное.'
    },
    {
        id: 5,
        name: 'Жилищное право',
        query: 'housing-right',
        description: 'Сопровождение купли-продажи квартиры/дома; изучение документов и проверка застройщика; выселения и выписка из квартиры/дома; помощь и консультации при затоплении, и тому подобное.'
    },
    {
        id: 6,
        name: 'Трудовое право',
        query: 'labor-right',
        description: 'Разъяснение о трудовых правах и гарантиях работника (отпуск, больничный, декретный отпуск); помощь во взыскании невыплаченной заработной платы; консультации по работе по гражданско-правовому договору, незаконного увольнения, и тому подобное.'
    },
    {
        id: 7,
        name: 'Дорожно-транспортное правонарушения',
        query: 'traffic-right',
        description: 'Помощь в дорожно-транспортных вопросах.'
    },
    {
        id: 8,
        name: 'Предпринимательская деятельность',
        query: 'business-right',
        description: 'Помощь в регистрации/ликвидации бизнеса (юридических лиц и физических лиц-придпринимателей), получении разрешительной документации и лецензирования; налоговые и финансовые консультации, сопровождение деятельности придпринимателей.'
    },
    {
        id: 9,
        name: 'Защита прав потребителя',
        query: 'consumer-protection',
        description: 'Защита прав потребителя'
    },
    {
        id: 10,
        name: 'Налоговое право',
        query: 'tax-right',
        description: 'Налоговое право'
    },
    {
        id: 11,
        name: 'Социальное обеспечение',
        query: 'social-protection',
        description: 'Помощь в получении пенсий по возрасту, по выслуге лет, по инвалидности, по случаю потери кормильца, содействие получения пособий по безработице, производственной травме, рождении ребенка и тому подобное.'
    }
]
