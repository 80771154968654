// import style from './MyAppeals.module.scss';

import { useState } from 'react';

// Components
import EmptyPage from '../../components/Home/EmptyPage/EmptyPage';
import { TelegramBackBtn } from '../../components/utils/buttons/Buttons';


const MyAppeals = () => {
    const [documents] = useState([]);

    return <>
        <TelegramBackBtn url='home/profile' />
        {documents.length ? (
            <>Appeals</>
        ) : (
            <EmptyPage title='Мои обращения' text='Здесь будет храниться история Ваших обращений' />
        )}
    </>
}

export default MyAppeals;