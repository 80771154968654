import { useEffect, useState } from 'react';
import style from './Chats.module.scss';

// Ant Design
import { RightOutlined } from '@ant-design/icons';

// Routers
import { Link } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import { Status } from '../../redux/auth/types';
import { useAppDispatch } from '../../redux/store';
import { selectMyChats } from '../../redux/chats/selectors';
import { requestGetChats } from '../../redux/chats/asyncActions';



// Components
import Spinner from '../../components/utils/spinner/Spinner';


const Chats = () => {
    const [active, setActive] = useState<number>(0);

    // Redux
    const dispatch = useAppDispatch();
    const { status, chats, closedChats } = useSelector(selectMyChats);

    // Get My Chats
    useEffect(() => {
        if (!active && !chats.length && status === Status.CALM) {
            dispatch(requestGetChats());
        }
    }, [chats, active, status, dispatch])

    return (
        <main>
            <div className={style.title}>
                <h1>Мои Чаты</h1>
            </div>
            {status === Status.LOADING ? (
                <Spinner />
            ) : (
                <>
                    <div className={style.toogle_menu}>
                        <button
                            onClick={() => setActive(0)}
                            className={active === 0 ? style.active : ''}>Текущие</button>
                        <button
                            onClick={() => setActive(1)}
                            className={active === 1 ? style.active : ''}>Архив</button>
                    </div>
                    {active === 0
                        ? (
                            chats.length > 0
                                ? (
                                    <div className={style.chats}>
                                        {chats.map((chat, idx) => (
                                            <div className={style.chat} key={idx}>
                                                <h2>"{chat.service_name}" из пакета "{chat.package_name}"</h2>
                                                <Link to={`/home/chat/${chat.request_id}`}>
                                                    <button>Перейти в чат</button>
                                                    <RightOutlined />
                                                </Link>
                                            </div> 
                                        ))}
                                    </div>
                                )
                                : (
                                    <div className={style.not_exist_text}>
                                        <p>У вас нет {active === 0 ? 'текущих' : 'архивных'} чатов</p>
                                    </div>
                                )
                        )
                        : (
                            closedChats.length > 0
                            ? (
                                <div className={style.chats}>
                                    {closedChats.map((chat, idx) => (
                                        <div className={style.chat} key={idx}>
                                            <h2>"{chat.service_name}" из пакета "{chat.package_name}"</h2>
                                            <Link to={`/`}>
                                                <button>Перейти в чат</button>
                                                <RightOutlined />
                                            </Link>
                                        </div> 
                                    ))}
                                </div>
                            )
                            : (
                                <div className={style.not_exist_text}>
                                    <p>У вас нет {active === 0 ? 'текущих' : 'архивных'} чатов</p>
                                </div>
                            )
                        )
                    }
                </>
            )}
        </main>
    )
}

export default Chats;