import style from './ServicesPay.module.scss';

// React
import { useEffect, useState } from 'react';

// Ant Design
import { message as antdMessage } from 'antd';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { setPackageByAlias } from '../../redux/packages/slice';
import { selectPackages } from '../../redux/packages/selectors';
import { PromoCodeFormData, Status } from '../../redux/promocode/types';
import { requestActivatePromoCode } from '../../redux/promocode/asyncActions';

// Telegram
import { useWebApp, MainButton, WebAppUser, useShowPopup } from '@vkruglikov/react-telegram-web-app';

// Route
import { useParams } from 'react-router-dom';

// Components
import Spinner from '../../components/utils/spinner/Spinner';
import { selectPayments } from '../../redux/payments/selectors';
import { setPaymentUrlIsNull } from '../../redux/payments/slice';
import { setPromocodeIsNull } from '../../redux/promocode/slice';
import { selectPromoCode } from '../../redux/promocode/selectors';
import { CreateUrlPaymentFormData } from '../../redux/payments/types';
import { requestMyServices } from '../../redux/my-services/asyncActions';
import { TelegramBackBtn } from '../../components/utils/buttons/Buttons';
import { requestCreatePaymentUrl } from '../../redux/payments/asyncActions';


const ServicesPay = () => {
    let { alias, type } = useParams();
    const [promocode, setPromocode] = useState<string>('');

    // Redux
    const dispatch = useAppDispatch();
    const { activePackage } = useSelector(selectPackages);

    useEffect(() => {
        if (alias && type) {
            dispatch(setPackageByAlias({
                alias: alias,
                type: type,
            }))
        }
    }, [dispatch, alias, type])

    // Show Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();
    const { status, message, promo_package } = useSelector(selectPromoCode);

    // Activate Promocode
    const handlerActivatePromocode = (e: any) => {
        e.preventDefault();
        e.target.disabled = true;

        if (promo_package) {
            messageApi.info('Вы уже активировали промокод', 2);
            e.target.disabled = false;
            return;
        }

        if (promocode.length === 0) {
            messageApi.error('Пожалуйста введите промокод', 2);
            e.target.disabled = false;
            return;
        }
 
        if (alias) {
            const formData: PromoCodeFormData = {
                promocode: promocode,
                service: alias,
            }
            dispatch(requestActivatePromoCode(formData));
        }
        e.target.disabled = false;
    }

    useEffect(() => {
        if (status !== Status.CALM && message.length > 0) {
            if (status === Status.SUCCESS) {
                messageApi.success(message, 2);
                return;
            }
            if (status === Status.ERROR) {
                messageApi.error(message, 2);
                return;
            }
        }
    }, [status, message, messageApi])

    // Payment Handler
    const WebApp = useWebApp();
    const showPopup = useShowPopup();
    const { payment_url } = useSelector(selectPayments);

    const payment_handler = () => {
        console.log('activePackage: ', activePackage);
        if (activePackage) {
            // const user: WebAppUser = WebApp.initDataUnsafe.user;
            
            const formData: CreateUrlPaymentFormData = {
                alias: activePackage.alias,
                price: promo_package ? promo_package.price : activePackage.price,
                // telegram_id: user.id,
                telegram_id: '210207653',
                description: activePackage.description,
                verbose_name: activePackage.verbose_name
            }
            dispatch(requestCreatePaymentUrl(formData));
        }
    }

    useEffect(() => {
        if (payment_url) {
            WebApp.openInvoice(payment_url);

            WebApp.onEvent('invoiceClosed', (object: any) => {
                if (object.status === 'paid') {
                    messageApi.success('Вы успешно оплатили услугу', 2);
                    return;
                }
                if (object.status === 'failed') {
                    messageApi.error('Произошла ошибка при оплате', 2);
                    return;
                }
                if (object.status === 'cancelled') {
                    messageApi.info('Вы отменили оплату', 2);
                    return;
                }
            });
        }
        return () => {
            dispatch(setPromocodeIsNull());
            dispatch(setPaymentUrlIsNull());
            dispatch(requestMyServices());
        }
    }, [payment_url, WebApp, dispatch])
    
    return (
        <div className={style.service_pay}>
            {contextHolder}
            <TelegramBackBtn url='home/service-info' />
            {!activePackage
                ? (
                    <Spinner />
                )
                : (
                    <>
                        <div className={style.title}>
                            <h1>Покупка Услуги</h1>
                        </div>
                        <div className={style.service_pay__name}>
                            <h2>Услуга {activePackage.verbose_name}</h2>
                        </div>
                        <div className={style.service_pay__price}>
                            <p>Стоимость</p>
                            <p>{promo_package ? promo_package.price : activePackage?.price} ₽</p>
                        </div>
                        <div className={style.service_pay__promocode}>
                            <p>Есть промокод?</p>
                            <form>
                                <input
                                    type="text"
                                    value={promocode}
                                    onChange={(e) => setPromocode(e.target.value)} />
                                <button onClick={handlerActivatePromocode}>Применить</button>
                            </form>
                        </div>
                    </>
                )}
                <MainButton text='Перейти к оплате' color='#F24259' onClick={payment_handler} />
        </div>
    )
}

export default ServicesPay;