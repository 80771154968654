import { createAsyncThunk } from '@reduxjs/toolkit';

import AuthService from '../../services/AuthService';
import { Profile } from '../profile/types';


export const requestAuth = createAsyncThunk('/telegram-web-app/v1/auth/telegram', async (formData: Profile) => {
    const response = await AuthService.auth(formData);
    return response;
})
